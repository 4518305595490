import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Avatar, Badge, Box, Button, Divider, Icon, IconButton, MenuItem, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { logOut } from "../../app/features/auth/auth-slice";
import MenuPopover from "../../components/menu-popover";

export default function AccountMenu({}) {
  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const account = {
    photoUrl: null,
    firstname: "",
    lastname: "",
    initials: "KA",
    displayName: "Kojo Agyemang",
    email: "agyemang@gmail.com",
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="medium"
        color="inherit"
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[800], 0.5),
            },
          }),
        }}
      >
        {account.photoUrl ? (
          <Avatar src={account.photoUrl} alt={account.initials} />
        ) : (
          <Avatar>{account.initials}</Avatar>
        )}
      </IconButton>
      <MenuPopover open={open} onClose={() => handleClose()} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {account.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {/* {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))} */}

        <Box sx={{ p: 2, pt: 1.5 }}>
          Use IconButton with Logout icon here
          <Button fullWidth color="inherit" variant="outlined" onClick={() => dispatch(logOut())}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
