import { useState } from "react";
import {
  Stepper,
  Box,
  Step,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Paper,
  StepLabel,
  Button,
  MobileStepper,
} from "@mui/material";
import PersonalDetails from "./personal-details";
import LocationSelector from "./location-selector";
import LocationModeSelector from "./location-mode-selector";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormValues } from "./form-values";
import Review from "./review";
import schema from "./validation";
import Complete from "./complete";
import { useNavigate } from "react-router-dom";

type StepDefinition = {
  handleNext: () => Promise<number>;
  handleBack: () => number;
};

const steps1 = ["Personal Details", "Location", "Map", "Review", "Complete"];
const steps2 = ["Personal Details", "Location", "Review", "Complete"];

export default function RegisterPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [locationMode, setLocationMode] = useState(0);
  const [isBusy, setIsBusy] = useState(false);
  const [registrationError, setRegistrationError] = useState<string | undefined>();

  const navigate = useNavigate();

  const form = useForm<FormValues>({
    defaultValues: {
      customerId: "",
      firstname: "",
      lastname: "",
      address: "",
      phonenumber: "",
      email: "",
      ghanaPostCode: "",
      googlePlusCode: "",
      password: "",
      confirmPassword: "",
    },
    resolver: zodResolver(schema),
  });

  const {
    register,
    trigger,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = form;

  const updateCoords = (longitude: number, latitude: number) => {
    setValue("longitude", longitude);
    setValue("latitude", latitude);
  };

  const validators = new Map<number, StepDefinition>();
  validators.set(0, {
    handleBack: () => -1,
    handleNext: async () => {
      const isValid = await trigger([
        "customerId",
        "firstname",
        "lastname",
        "phonenumber",
        "email",
        "address",
        "password",
        "confirmPassword",
      ]);

      return isValid ? 1 : -1;
    },
  });

  validators.set(1, {
    handleBack: () => 0,
    handleNext: async () => {
      switch (locationMode) {
        case 2: {
          const isValid = await trigger(["googlePlusCode"]);
          if (!isValid) {
            return -1;
          }

          const { googlePlusCode } = getValues();

          setIsBusy(true);
          try {
            const response = await fetch(
              `https://plus.codes/api?address=6CQX${googlePlusCode?.replace("+", "%2B")}&email=YOUR_EMAIL_HERE`
            );
            const data = await response.json();
            const { lng, lat } = data.plus_code.geometry.location;

            updateCoords(lng, lat);

            return 2;
          } catch {
            setError("googlePlusCode", { message: "Given Google Plus Code not valid" });
            return -1;
          } finally {
            setIsBusy(false);
          }
        }

        default:
          return 3;
      }
    },
  });

  validators.set(2, {
    handleBack: () => 1,
    handleNext: async () => 3,
  });

  validators.set(3, {
    handleBack: () => (locationMode < 2 ? 1 : 2),
    handleNext: async () => {
      setIsBusy(true);
      try {
        const response = await fetch("https://customer.wastecollectionmanager.com/api/register", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...getValues(), locationMode }),
        });

        if (response.status === 204) {
          return 4;
        }

        const { error } = await response.json();
        setRegistrationError(error);
      } catch {
        setRegistrationError("An error occured during registration");
      } finally {
        setIsBusy(false);
      }

      return -1;
    },
  });

  validators.set(4, {
    handleBack: () => -1,
    handleNext: async () => {
      navigate("/login", { replace: true });
      return -1;
    },
  });

  const handleBack = () => {
    const backStep = validators.get(activeStep)?.handleBack();
    if (typeof backStep === "number") {
      setActiveStep(backStep);
    }
  };

  const handleNext = async () => {
    const nextStep = await validators.get(activeStep)?.handleNext();
    if (nextStep && nextStep > -1) {
      setActiveStep(nextStep);
    }
  };

  const values = getValues();

  return (
    <>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="primary"
        elevation={1}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Waste Collection Manager - Registration
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="md">
        {isBusy ? (
          <p>Loading...</p>
        ) : (
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h4" align="center" sx={{ mb: 2 }}>
              Register Customer
            </Typography>

            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5, xs: { display: "none" } }} alternativeLabel>
              {(locationMode < 2 ? steps2 : steps1).map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <form noValidate>
              {activeStep === 0 && <PersonalDetails errors={errors} register={register} />}
              {activeStep === 1 && (
                <LocationModeSelector
                  mode={locationMode}
                  setMode={setLocationMode}
                  errors={errors}
                  register={register}
                />
              )}
              {activeStep === 2 && (
                <LocationSelector updateCoords={updateCoords} longitude={values.longitude} latitude={values.latitude} />
              )}
              {activeStep === 3 && <Review values={values} />}
              {activeStep === 4 && <Complete error={registrationError} firstname={values.firstname} />}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && activeStep !== 4 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}
                <Button variant="contained" onClick={handleNext} sx={{ mt: 3, ml: 1 }}>
                  {activeStep === 3 ? "Register" : activeStep === 4 ? "Proceed to Login" : "Next"}
                </Button>
              </Box>
            </form>
          </Paper>
        )}
      </Container>
    </>
  );
}
