import { Typography } from "@mui/material";

export default function Complete({ firstname, error }: { firstname?: string; error?: string }) {
  return error ? (
    <>
      <Typography variant="h2">Error!</Typography>
      <Typography variant="h5">Hi {firstname}</Typography>
      <Typography variant="subtitle1">Your registration failed with error: {error}</Typography>
    </>
  ) : (
    <>
      <Typography variant="h2" gutterBottom>
        Success!
      </Typography>
      <Typography variant="h5" gutterBottom>
        Hi {firstname}
      </Typography>
      <Typography variant="subtitle1">
        Your registration was successful. We have sent email with a link for you to confirm your email address. Please
        click on the link to confirm your email address. There's also a link for you to set your bin location which you
        can click when you are close to your bin with a GPS-enabled mobile phone.
      </Typography>
    </>
  );
}
