import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { ChangeEvent, ChangeEventHandler, useState } from "react";

export default function ContactUsDetails({
  queryType,
  setQueryType,
  text,
  setText,
}: {
  queryType: string;
  setQueryType: (value: string) => void;
  text: string;
  setText: (value: string) => void;
}) {
  const [count, setCount] = useState(text.length);

  const onTextChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    const areaText = (evt.currentTarget as HTMLTextAreaElement)?.value;

    setText(areaText);
    setCount(areaText?.length ?? 0);
  };

  return (
    <Stack>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="type-of-query-label">Type of Query</InputLabel>
        <Select
          labelId="type-of-query-label"
          id="type-of-query"
          value={queryType}
          label="Type of Query"
          onChange={(evt) => setQueryType(evt.target.value)}
        >
          <MenuItem value="General">General</MenuItem>
          <MenuItem value="MissedCollection">Missed Collection</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1 }}>
        <Typography variant="subtitle2">Query:</Typography>
        <TextareaAutosize value={text} onChange={onTextChange} minRows={5} maxRows={20} maxLength={2000} />
        <FormHelperText>{count} of 2000 characters</FormHelperText>
      </FormControl>
    </Stack>
  );
}
