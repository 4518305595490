import { number, string, z } from "zod";

const schema = z.object({
  amount: number().min(20, { message: "Minimum amount of GHS20.00 is required" }),
  phonenumber: string().regex(/^(((00|\+)233)|(0))(2|5)\d{8}$/, {
    message: "Phone number should be of format eg 0201234567",
  }),
});

export default schema;
