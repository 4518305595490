import { Card } from "@mui/material";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Collection } from "../../app/features/dashboard/dashboard-slice";

const ServerDay = (props: PickersDayProps<Dayjs> & { collections?: Collection[] }) => {
  const { collections = [], day, ...other } = props;

  const collectionDay = collections.find((c) => dayjs(c.time).isSame(day, "day"));

  let bgcolor = "none";
  switch (collectionDay?.status) {
    case "Missed":
      bgcolor = "error.main";
      break;
    case "Collected":
      bgcolor = "primary.main";
      break;
    case "Due":
      bgcolor = "secondary.main";
      break;
  }

  return (
    <PickersDay
      {...other}
      day={day}
      sx={{
        bgcolor,
        color: !!collectionDay ? "primary.contrastText" : "text.primary",
      }}
    />
  );
};

export default function CollectionDates({ collections }: { collections: Collection[] }) {
  return (
    <Card>
      <DateCalendar readOnly views={["day"]} slotProps={{ day: { collections } as any }} slots={{ day: ServerDay }} />
    </Card>
  );
}
