import { apiSlice } from "../../api-slice";
import { Transaction } from "../dashboard/dashboard-slice";

export interface PaymentData {
  amount: number;
  phonenumber: string;
  mobileMoneyProvider: string;
}

export const paymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    makePayment: builder.mutation<Transaction, PaymentData>({
      query: (payment: PaymentData) => ({
        url: "/api/make-payment",
        method: "POST",
        body: { ...payment },
      }),
    }),
  }),
});

export const { useMakePaymentMutation } = paymentApiSlice;
