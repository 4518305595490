import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface AuthState {
  user: string | null;
  token: string | null;
  firstname?: string;
  lastname?: string;
  lastLoginTime?: string;
}

const initialState: AuthState = {
  user: null,
  token: null,
  firstname: "Isaac",
  lastname: "Darfour",
  lastLoginTime: "2023-09-30T11:23:45",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken } = action.payload;
      state.user = user;
      state.token = accessToken;
    },
    setUserInfo: (state, action) => {
      const { firstname, lastname, lastLoginTime } = action.payload;
      state.firstname = firstname;
      state.lastname = lastname;
      state.lastLoginTime = lastLoginTime;
    },
    logOut: (state) => {
      state.token = null;
      state.user = null;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentToken = (state: RootState) => state.auth.token;
export const selectUserInfo = (state: RootState) => ({
  firstname: state.auth.firstname,
  lastname: state.auth.lastname,
  lastLoginTime: state.auth.lastLoginTime,
});

export default authSlice.reducer;
