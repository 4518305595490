import { Box, Stack, Typography } from "@mui/material";
import { Transaction } from "../../app/features/dashboard/dashboard-slice";
import dayjs from "dayjs";

export default function PaymentResult({ isError, transaction }: { isError: boolean; transaction?: Transaction }) {
  return isError ? (
    <Typography variant="h6" color="error" sx={{ my: 5, mx: 3 }}>
      Error occured in making payment
    </Typography>
  ) : (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h6">Payment Successful</Typography>
        <Typography variant="body1">
          Your payment request was successful. Please follow the instructions from the prompt from your Mobile Money
          provider to complete the payment
        </Typography>
      </Box>
      <Typography variant="body1">Your payment request details are as follows</Typography>
      <Box>
        <Typography variant="subtitle1">Transaction ID</Typography>
        <Typography>{transaction?.id}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">Date/Time</Typography>
        <Typography>{dayjs(transaction?.time).format("dd MMM, YYYY HH:mm")}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">Amount </Typography>
        <Typography>GHS {transaction?.amount}</Typography>
      </Box>
    </Stack>
  );
}
