import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { FormValues } from "./form-values";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { Box, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";

export default function PersonalDetails({
  register,
  errors,
}: {
  errors: FieldErrors<FormValues>;
  register: UseFormRegister<FormValues>;
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Personal Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Customer ID"
            fullWidth
            variant="standard"
            {...register("customerId")}
            error={!!errors.customerId}
            helperText={errors.customerId?.message}
          />
        </Grid>
        <Box component={Grid} item sm={6} display={{ xs: "none", sm: "block" }}></Box>
        <Grid item xs={12} sm={6}>
          <TextField
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            {...register("firstname")}
            error={!!errors.firstname}
            helperText={errors.firstname?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            {...register("lastname")}
            error={!!errors.lastname}
            helperText={errors.lastname?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            fullWidth
            autoComplete="email"
            variant="standard"
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Phone Number"
            fullWidth
            autoComplete="phonenumber"
            variant="standard"
            {...register("phonenumber")}
            error={!!errors.phonenumber}
            helperText={errors.phonenumber?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            {...register("address")}
            error={!!errors.address}
            helperText={errors.address?.message}
            sx={{ mb: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Password"
            fullWidth
            variant="standard"
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password?.message}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(evt) => evt.preventDefault()}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Confirm Password"
            fullWidth
            variant="standard"
            {...register("confirmPassword")}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            type={showConfirmPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    onMouseDown={(evt) => evt.preventDefault()}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
