import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Collection } from "../../app/features/dashboard/dashboard-slice";
import dayjs from "dayjs";

export default function CollectionDatesTable({ collections }: { collections: Collection[] }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell sx={{ display: { xs: "none", sm: "table-cell" } }}>Time</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collections.slice(0, 5).map((r) => (
            <TableRow key={r.time}>
              <TableCell>{dayjs(r.time).format("DD MMMM, YYYY")}</TableCell>
              <TableCell sx={{ display: { xs: "none", sm: "table-cell" } }}>{dayjs(r.time).format("HH:mm")}</TableCell>
              <TableCell>{r.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
