import { Box, Card, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import TransactionsTable from "./transactions-table";
import CollectionDatesCalendar from "./collection-dates-calendar";
import CollectionDatesTable from "./collection-dates-table";
import { selectDashboard } from "../../app/features/dashboard/dashboard-slice";
import { useAppSelector } from "../../app/hooks";
import { selectUserInfo } from "../../app/features/auth/auth-slice";
import dayjs from "dayjs";

export default function Dashboard() {
  const { dashboard } = useAppSelector(selectDashboard);
  const { firstname, lastname, lastLoginTime } = useAppSelector(selectUserInfo);

  return (
    <Container>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Stack sx={{ mt: 2, mb: 1 }} direction="column" display="flex" justifyContent="center">
            <Typography variant="h6" align="center">
              Welcome, {firstname} {lastname}
            </Typography>

            <Typography align="center">
              Last Logged in: {dayjs(lastLoginTime).format("dddd, DD MMMM, YYYY HH:mm:ss")}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card sx={{ bgcolor: "rgb(30,136,229)", color: "primary.contrastText", p: 2 }}>
            <Typography variant="h6">Your account is 500GHS in credit</Typography>
            <Typography>How are you?</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6">Next predicted collection:</Typography>
            <Typography>Monday, 25th October, 2023</Typography>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Collections</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CollectionDatesCalendar collections={dashboard?.collections ?? []} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <CollectionDatesTable collections={dashboard?.collections ?? []} />
        </Grid>
        <Grid item xs={12}>
          <TransactionsTable transactions={dashboard?.transactions ?? []} />
        </Grid>
      </Grid>
    </Container>
  );
}
