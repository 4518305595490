import { useEffect, useRef } from "react";
import { Box, Grid } from "@mui/material";
import * as ol from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import Zoom from "ol/control/Zoom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Point } from "ol/geom";
import { Modify, Snap } from "ol/interaction";
import { fromLonLat, toLonLat } from "ol/proj";

export default function LocationSelector({
  updateCoords,
  longitude,
  latitude,
}: {
  updateCoords: (longitude: number, latitude: number) => void;
  longitude?: number;
  latitude?: number;
}) {
  const mapRef = useRef<HTMLDivElement>(null);

  const updateTag = (fieldname: string, value: number) => {
    const tag = document.getElementById(fieldname.toLowerCase());
    if (tag) tag.innerText = `${fieldname}: ${value.toFixed(4)}`;
  };

  useEffect(() => {
    const tileSource = new OSM();
    const layer = new TileLayer({ source: tileSource });

    const coords = fromLonLat([longitude!, latitude!]);
    updateTag("Longitude", longitude!);
    updateTag("Latitude", latitude!);

    const vectorSource = new VectorSource();
    vectorSource.addFeature(new ol.Feature({ geometry: new Point(coords) }));
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: {
        "fill-color": "rgba(255, 255, 255, 0.2)",
        "stroke-color": "#ffcc33",
        "stroke-width": 2,
        "circle-radius": 7,
        "circle-fill-color": "#ffcc33",
      },
    });
    const map = new ol.Map({
      view: new ol.View({ zoom: 16, center: coords }),
      layers: [layer, vectorLayer],
      controls: [new Zoom()],
    });

    const modify = new Modify({ source: vectorSource });
    modify.on("modifyend", (e) => {
      const feature = e.features.getArray()[0];

      const pt = feature.getGeometry() as Point;

      const newCoords = toLonLat(pt.getCoordinates());
      const [lon, lat] = newCoords;

      updateTag("Longitude", lon);
      updateTag("Latitude", lat);

      updateCoords(lon, lat);
    });
    const snap = new Snap({ source: vectorSource });

    map.addInteraction(modify);
    map.addInteraction(snap);

    map.setTarget(mapRef.current || undefined);

    return () => {
      map.removeLayer(layer);
      map.removeLayer(vectorLayer);
      map.setTarget(undefined);
    };
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ height: 500, border: "1px solid #bbb" }}>
            <div ref={mapRef} style={{ height: "100%", width: "100%" }}></div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <p id="longitude"></p>
          <p id="latitude"></p>
        </Grid>
      </Grid>
    </>
  );
}
