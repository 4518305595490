import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface Transaction {
  id: string;
  time: string;
  mode: string;
  amount: number;
}

export interface Collection {
  time?: string;
  status: "Missed" | "Collected" | "Due";
}

export interface Dashboard {
  transactions: Transaction[];
  collections: Collection[];
}

export interface DashboardState {
  dashboard?: Dashboard;
}

const initialState: DashboardState = {
  dashboard: {
    transactions: [
      {
        id: "1",
        time: "2023-09-04T13:05:22",
        mode: "MTN Mobile Money",
        amount: 20,
      },
      {
        id: "2",
        time: "2023-09-04T13:05:22",
        mode: "Bill",
        amount: -20,
      },
      {
        id: "3",
        time: "2023-09-14T11:35:22",
        mode: "Vodafone Cash",
        amount: 40,
      },
    ],
    collections: [
      { status: "Due", time: "2023-10-06" },
      {
        time: "2023-09-30T12:05:21",
        status: "Collected",
      },
      {
        time: "2023-09-23",
        status: "Missed",
      },
      {
        time: "2023-09-16T09:55:21",
        status: "Collected",
      },
      {
        time: "2023-09-09T14:32:21",
        status: "Collected",
      },
      {
        time: "2023-09-02T15:11:21",
        status: "Collected",
      },
    ],
  },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboard: (state, action) => {
      const { dashboardData } = action.payload;
      state.dashboard = dashboardData;
    },
  },
});

export const { setDashboard } = dashboardSlice.actions;

export const selectDashboard = (state: RootState) => state.dashboard;

export default dashboardSlice.reducer;
