import { styled, CSSObject, Theme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { Box, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MapIcon from "@mui/icons-material/Map";
import LogOutIcon from "@mui/icons-material/Logout";
import PaidIcon from "@mui/icons-material/Paid";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: theme.spacing(7),
  [theme.breakpoints.up("sm")]: {
    width: theme.spacing(9),
  },
});

const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  whiteSpace: "nowrap",
  width: drawerWidth,
  boxSizing: "border-box",
  flexShrink: 0,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideDrawer({ open, setOpen }: { open: boolean; setOpen: (v: boolean) => void }) {
  const primaryMenuItems = [
    { caption: "Dashboard", icon: <DashboardIcon />, to: "/" },
    { caption: "Make Payment", icon: <PaidIcon />, to: "/make-payment" },
    { caption: "Contact Us", icon: <MapIcon />, to: "/contact-us" },
  ];

  const drawerContent = (
    <>
      <Toolbar></Toolbar>
      <Stack sx={{ flexGrow: 1, justifyContent: "space-between" }}>
        <Box onClick={() => setOpen(false)}>
          <List>
            {primaryMenuItems.map((link) => (
              <li key={link.caption}>
                <ListItemButton component={Link} to={link.to}>
                  <ListItemIcon>{link.icon}</ListItemIcon>
                  <ListItemText primary={link.caption} />
                </ListItemButton>
              </li>
            ))}
          </List>

          <Divider />
        </Box>
        <Box>
          <List>
            <li>
              <ListItemButton component={Link} to="/login">
                <ListItemIcon>
                  <LogOutIcon />
                </ListItemIcon>
                <ListItemText primary="Log Out" />
              </ListItemButton>
            </li>
          </List>
        </Box>
      </Stack>
    </>
  );

  return (
    <>
      <CustomDrawer variant="permanent" open={open} sx={{ display: { xs: "none", sm: "block" } }}>
        {drawerContent}
      </CustomDrawer>
      <Drawer
        variant="temporary"
        open={open}
        onClose={(_, reason) => reason === "backdropClick" && setOpen(false)}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
}
