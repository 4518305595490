import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/authentication/login-page";
import RegisterPage from "./pages/authentication/register-page";
import Layout from "./layout";
import Dashboard from "./pages/dashboard";
import PaymentPage from "./pages/payment";
import ContactUsPage from "./pages/contact-us";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="/make-payment" element={<PaymentPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="*" element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  );
}
