import { string, z } from "zod";

const schema = z
  .object({
    customerId: string().min(1, { message: "Customer ID is required" }),
    firstname: string()
      .min(1, { message: "First Name is required" })
      .regex(new RegExp(/^[a-zA-Z]+$/)),
    lastname: string().min(1, { message: "Last name is required" }),
    email: string().email(),
    phonenumber: string().regex(/^(((00|\+)233)|(0))(2|5)\d{8}$/, {
      message: "Phone number should be of format eg 0201234567",
    }),
    address: string().min(1, { message: "Address is required" }),
    googlePlusCode: string().regex(/^[a-zA-Z0-9]{4}\+[a-zA-Z0-9]{2}$/, {
      message: "Google Plus code should be of the form AAAA+AA",
    }),
    password: string().min(6, { message: "Password must be at least 6 charecters" }),
    confirmPassword: string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export default schema;
