import { Grid, Typography } from "@mui/material";
import { FormValues } from "./form-values";

export default function Review({ values }: { values: FormValues }) {
  const { customerId, firstname, lastname, address, email, phonenumber } = values;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Review
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Customer ID
        </Typography>
        <Typography variant="body1">{customerId}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Name
        </Typography>
        <Typography variant="body1">
          {firstname} {lastname}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" gutterBottom>
          Email
        </Typography>
        <Typography variant="body1">{email}</Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="h6" gutterBottom>
          Phone Number
        </Typography>
        <Typography variant="body1">{phonenumber}</Typography>
      </Grid>

      <Grid item sm={12}>
        <Typography variant="h6" gutterBottom>
          Address
        </Typography>
        <Typography variant="body1">{address}</Typography>
      </Grid>
    </Grid>
  );
}
