import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { FormValues } from "./form-values";
import { FieldErrors, UseFormRegister } from "react-hook-form";

export default function LocationModeSelector({
  mode,
  setMode,
  register,
  errors,
}: {
  mode: number;
  setMode: (mode: number) => void;
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <FormControl>
          <FormLabel>Location</FormLabel>
          <RadioGroup name="location-selector" value={mode} onChange={(evt) => setMode(parseInt(evt.target.value))}>
            <FormControlLabel value="0" control={<Radio />} label="Use Link on Mobile" />
            <FormControlLabel value="1" control={<Radio />} label="Use Ghana Post Code" />
            <FormControlLabel value="2" control={<Radio />} label="Use Google Plus Code" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={8}>
        {mode === 0 && (
          <>
            <Typography variant="h6" color="inherit" noWrap>
              Using Link on Mobile Phone (Recommended)
            </Typography>
            <Typography color="inherit">
              This is the recommended way to submit the location of your bin as it is the easiest. You will receive an
              email after registration. This email will contain a link to a webpage where you can click to submit your
              current location. For this to work, you'll have to allow the webpage to access your location using the
              phone's GPS. Once that is done, just click on submit location and your bin location will be recorded.
            </Typography>
          </>
        )}
        {mode === 1 && (
          <>
            <Typography variant="h6" color="inherit" noWrap>
              Using your home's Ghana GPS Post Code
            </Typography>
            <Typography color="inherit">
              Input your full Ghana GPS Post Code into the box below and that will be used as your bin location
            </Typography>
            <TextField
              {...register("ghanaPostCode")}
              label="Ghana GPS Post Code"
              fullWidth
              variant="standard"
              error={!!errors.ghanaPostCode?.message}
              helperText={errors.ghanaPostCode?.message}
            />
          </>
        )}
        {mode === 2 && (
          <>
            <Typography variant="h6" color="inherit" noWrap>
              Using your home's Google Plus Code
            </Typography>
            <Typography color="inherit">
              If you know your home's Google Plus Code, you can input it here to get your bin's location. Your Google
              Plus Code can be obtained from Google Maps in your browser or your phone and comprises of 4 characters
              followed by a + and 2 more characters. Example PW55+RQ Accra. In that case, only enter PW55+RQ
            </Typography>
            <TextField
              {...register("googlePlusCode")}
              label="Google Plus Code"
              fullWidth
              variant="standard"
              error={!!errors.googlePlusCode?.message}
              helperText={errors.googlePlusCode?.message}
              sx={{ my: 3 }}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}
