import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import { FormValues } from ".";
import { FieldErrors, UseFormRegister } from "react-hook-form";

export default function PaymentDetails({
  mobileMoneyProvider,
  setMobileMoneyProvider,
  register,
  errors,
}: {
  mobileMoneyProvider: string;
  setMobileMoneyProvider: (value: string) => void;
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
}) {
  return (
    <form noValidate>
      <Stack spacing={5} sx={{ m: 3 }}>
        <TextField
          label="Amount (GHS)"
          fullWidth
          variant="standard"
          type="number"
          {...register("amount", { valueAsNumber: true })}
          error={!!errors.amount}
          helperText={errors.amount?.message}
          onFocus={(evt) => {
            const target = evt.target;
            setTimeout(() => target.select(), 0);
          }}
        />

        <FormControl>
          <FormLabel>Mobile Money Provider</FormLabel>
          <RadioGroup
            name="mobileMondeyProvider"
            value={mobileMoneyProvider}
            onChange={(evt) => setMobileMoneyProvider(evt.target.value)}
          >
            <FormControlLabel value="MTN" control={<Radio />} label="MTN" />
            <FormControlLabel value="Vodafone" control={<Radio />} label="Vodafone Cash" />
            <FormControlLabel value="AirtelTigo" control={<Radio />} label="AirtelTigo Cash" />
          </RadioGroup>
        </FormControl>

        <TextField
          label="Mobile Money Phone Number"
          fullWidth
          variant="standard"
          {...register("phonenumber")}
          error={!!errors.phonenumber}
          helperText={errors.phonenumber?.message}
        />
      </Stack>
    </form>
  );
}
