import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  MobileStepper,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import PaymentDetails from "./payment-details";
import PaymentReview from "./payment-review";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import schema from "./validation";
import { useMakePaymentMutation } from "../../app/features/payment/payment-api-slice";
import { Transaction } from "../../app/features/dashboard/dashboard-slice";
import PaymentResult from "./payment-result";

const steps = ["Details", "Review", "Confirm"];

export type FormValues = {
  phonenumber: string;
  mobileMoneyProvider: string;
  amount: number;
};

export default function PaymentPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [mobileMoneyProvider, setMobileMoneyProvider] = useState("MTN");
  const [transaction, setTransaction] = useState<Transaction>();

  const navigate = useNavigate();
  const [makePayment, { isLoading, isError }] = useMakePaymentMutation();

  const form = useForm<FormValues>({
    defaultValues: {
      amount: 0,
      phonenumber: "",
    },
    resolver: zodResolver(schema),
  });

  const {
    register,
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  const values = getValues();

  const handleNext = async () => {
    switch (activeStep) {
      case 0:
        if (await trigger()) {
          setActiveStep(1);
        }
        break;

      case 1:
        try {
          //const { amount, mobileMoneyProvider, phonenumber } = values;
          const result = await makePayment(values).unwrap();
          setTransaction(result);
          setActiveStep(2);
        } catch (err) {
          console.error(err, "LLL");
          setActiveStep(2);
        }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <Backdrop open={isLoading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="sm" component="main" sx={{ my: 3 }}>
        <Paper variant="outlined">
          <Typography component="h2" variant="h6" align="center" sx={{ my: 2 }}>
            {isError ? "Error" : steps[activeStep]}
          </Typography>

          {activeStep === 0 && (
            <PaymentDetails
              mobileMoneyProvider={mobileMoneyProvider}
              setMobileMoneyProvider={setMobileMoneyProvider}
              register={register}
              errors={errors}
            />
          )}
          {activeStep === 1 && <PaymentReview data={{ ...values, mobileMoneyProvider }} />}
          {activeStep === 2 && (
            <Stack spacing={3} sx={{ m: 3 }}>
              <PaymentResult isError={isError} transaction={transaction} />
              <Button variant="text" onClick={() => navigate("/")}>
                Finish
              </Button>
            </Stack>
          )}
          {activeStep !== 2 && (
            <MobileStepper
              sx={{ display: { xs: "flex", sm: "flex" } }}
              variant="text"
              steps={steps.length}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button size="small" onClick={handleNext}>
                  {activeStep == 0 ? "Review" : "Make Payment"}
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack}>
                  {activeStep === 0 ? "Cancel" : "Back"}
                </Button>
              }
            />
          )}
        </Paper>
      </Container>
    </>
  );
}
