import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { createApi, FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "./features/auth/auth-slice";
import { RootState } from "./store";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://customer.electionsghana.com",
  credentials: "same-origin",
  prepareHeaders: (headers, { getState }: Pick<BaseQueryApi, "getState">) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    headers.set("credentials", "omit");
    return headers;
  },
});

const baseQueryWithReauth = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    console.log("send refresh token");

    // get refresh token
    const refreshResult = await baseQuery("/api/auth/refresh", api, extraOptions);
    console.log(refreshResult);
    if (refreshResult?.data) {
      const { data } = refreshResult;
      const user = (api.getState() as RootState).auth.user;
      // set user and token
      api.dispatch(setCredentials({ ...(data as any), user }));

      // retry original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "main",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
