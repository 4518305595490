import { ThunkAction, configureStore, Action } from "@reduxjs/toolkit";
import { apiSlice } from "./api-slice";
import authReducer from "./features/auth/auth-slice";
import dashboardReducer from "./features/dashboard/dashboard-slice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    dashboard: dashboardReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
