import { apiSlice } from "../../api-slice";

export interface ContactUsData {
  queryType: string;
  text: string;
}

export const contactUsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    contactUs: builder.mutation<any, ContactUsData>({
      query: (contactUs: ContactUsData) => ({
        url: "/api/contact-us",
        method: "POST",
        body: { ...contactUs },
      }),
    }),
  }),
});

export const { useContactUsMutation } = contactUsApiSlice;
