import { Backdrop, CircularProgress, Container, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useContactUsMutation } from "../../app/features/contact-us/contact-us-api-slice";
import { useNavigate } from "react-router-dom";
import ContactUsDetails from "./contact-us-details";

const steps = ["Contact Us", "Success"];

export default function ContactUsPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [queryType, setQueryType] = useState("General");
  const [text, setText] = useState("");
  const [contactUs, { isLoading, isError }] = useContactUsMutation();
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" component="main" sx={{ my: 3 }}>
      <Backdrop open={isLoading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper variant="outlined">
        <Typography component="h2" variant="h6" align="center" sx={{ my: 2 }}>
          {isError ? "Error" : steps[activeStep]}
        </Typography>
        {activeStep === 0 && (
          <ContactUsDetails queryType={queryType} setQueryType={setQueryType} text={text} setText={setText} />
        )}
      </Paper>
    </Container>
  );
}
