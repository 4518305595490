import { Grid, Stack, Typography } from "@mui/material";
import { FormValues } from ".";

export default function PaymentReview({ data }: { data: FormValues }) {
  const { phonenumber, amount, mobileMoneyProvider } = data;
  return (
    <Stack sx={{ m: 3 }}>
      <Typography variant="subtitle2">Mobile Money Provider</Typography>
      <Typography variant="body2" sx={{ mb: 3 }}>
        {mobileMoneyProvider}
      </Typography>

      <Typography variant="subtitle2">Mobile Money Phone Number</Typography>
      <Typography variant="body2" sx={{ mb: 3 }}>
        {phonenumber}
      </Typography>
      <Typography variant="subtitle2">Mobile Money Provider</Typography>
      <Typography variant="body2">GHS {amount}</Typography>
    </Stack>
  );
}
